const styles = (theme) => {
  const drawerWidth = 208;
  return {
    ...theme.classes,
    appBar: {
      background: theme.palette.background.paper,
      color: theme.palette.header.primary,
      width: `calc(100% - ${drawerWidth}px)`,
      boxShadow: 'none',
      minHeight: '80px',
      paddingRight: 36,
      top: 60,
      '&.orders, &.supplyreceipts, &.purchaseorders': {
        display: 'none',
      },
      '@media (max-width:1240px)': {
        width: '100%',
        padding: 0,
        minHeight: '60px',
        top: 0,
        position: 'relative',
        zIndex: 1111,
        '&.orders, &.supplyreceipts, &.purchaseorders': {
          display: 'block',
        },
        '& .filters': {
          display: 'none',
        },
        '&.support': {
          minHeight: 50,
        },
        '&.supplyreceipts': {
          minHeight: 50,
        },
        '&.support .toolbar1, &.support .toolbar2': {
          backgroundColor: theme.palette.background.paper,
        },
        '&.support .toolbar2': {
          position: 'absolute',
          right: 0,
          width: '50%',
        },
      },
    },
    toolbar: {
      padding: 0,
      '@media (max-width:1240px)': {
        padding: '0 8px 0 20px',
      },
    },
    toolbarmobile: {
      display: 'none',
      background: theme.palette.common.white,
      padding: '0 20px',
      '@media (max-width:1240px)': {
        display: 'block',
        background: '#f6f7f7',
      },
    },
    toolbarBorder: {
      borderBottom: '1px solid #E8EAEE',
    },
    toolbarHeight: {
      minHeight: '80px',
      paddingTop: 16,
      '@media (max-width:1240px)': {
        minHeight: '60px',
        paddingTop: 0,
      },
    },
    icon: {
      verticalAlign: 'middle',
      cursor: 'pointer',
      color: theme.palette.secondary.main,
    },
    filterColumns: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      zIndex: 2,
      '& span': {
        fontSize: 12,
        fontWeight: 400,
        verticalAlign: 'middle',
        color: theme.palette.secondary.main,
        cursor: 'pointer',
      },
      '@media (max-width:370px)': {
        maxWidth: '50%',
        flexBasis: '50%',
      },
    },
    leftcontainer: {
      position: 'relative',
      zIndex: 3,
      display: 'inline-flex',
      '@media (max-width:1240px)': {
        display: 'inline-block',
      },
      '@media (max-width:370px)': {
        maxWidth: '50%',
        flexBasis: '50%',
      },
    },
    add: {
      display: 'inline-block',
      '& svg': {
        stroke: theme.palette.secondary.main,
      },
    },
    searchbox: {
      position: 'relative',
    },
    absearch: {
      position: 'absolute',
      top: 5,
      left: 0,
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: '#fff',
      },
      width: 352,
      height: 30,
      borderRadius: 100,
      display: 'inline-block',
      boxShadow: '0 0 6px rgba(68, 107, 176, 0.91)',
      '&.infilter': {
        width: 242,
        boxShadow: 'none',
      },
      '@media (max-width:1240px)': {
        right: 0,
        left: 'inherit',
      },
      '@media (max-width:420px)': {
        width: 302,
      },
    },
    absearchicon: {
      position: 'absolute',
      transform: 'rotateY(-180deg)',
      top: 3,
      left: 12,
      '& svg': {
        fill: '#09B0EE',
        width: 20,
      },
      '&.infilter svg': {
        fill: theme.palette.secondary.main,
      },
    },
    inputRoot: {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 400,
      width: '100%',
      padding: '0 20px 0 40px',
      height: '100%',
    },
    inputInput: {
      width: '100%',
    },
    searchicon: {
      marginRight: 2,
      padding: '12px 14px',
    },
    search: {
      display: 'inline-block',
      '& svg': {
        display: 'inline-block',
      },
      '& span': {
        fontSize: 12,
        fontWeight: 300,
        display: 'inline-block',
        color: theme.palette.primary.light,
      },
    },
    addtitle: {
      fontSize: 12,
      fontWeight: 300,
      display: 'inline-block',
      color: theme.palette.secondary.main,
      margin: '0 20px 0 4px',
      cursor: 'pointer',
      '&.infilter': {
        fontWeight: 400,
        color: '#fff',
        margin: '0 20px 0 0',
      },
      '@media (max-width:1240px)': {
        display: 'none',
      },
    },
    stitle: {
      cursor: 'pointer',
      color: `${theme.palette.secondary.main} !important`,
      display: 'inline-block',
      '&.infilter': {
        fontSize: 12,
        color: `${theme.palette.primary.light} !important`,
      },
      '@media (max-width:1240px)': {
        display: 'none !important',
      },
    },
    closesearch: {
      position: 'absolute',
      right: 10,
      top: 3,
      width: 18,
      cursor: 'pointer',
    },
    barcontainer: {
      '& .syncbtn.desktop': {
        display: 'inline-block',
        verticalAlign: 'middle',
        margin: '0 22px 0 12px',
        cursor: 'pointer',
        width: 20,
        height: 20,
      },
      '& .syncbtn.mobile': {
        display: 'none',
      },
      '@media (max-width:1240px)': {
        flexDirection: 'row-reverse',
        '& .leftGrid': {
          maxWidth: '30%',
          flexBasis: '30%',
          textAlign: 'right',
        },
        '& .rightGrid': {
          maxWidth: '70%',
          flexBasis: '70%',
          justifyContent: 'flex-start',
        },
        '& .submitWrapper': {
          display: 'none',
        },
        '& .syncbtn.desktop': {
          display: 'none',
        },
        '& .syncbtn.mobile': {
          display: 'inline-block',
          verticalAlign: 'middle',
        },
      },
    },
    bardesktop: {
      '@media (max-width:1240px)': {
        '& .lastupdate': {
          display: 'none',
        },
      },
    },
    barmobile: {
      display: 'none',
      '& .topleftGrid': {
        textAlign: 'right',
      },
      '& .toprightGrid': {
        justifyContent: 'flex-start',
      },
      '@media (max-width:1240px)': {
        display: 'flex',
        height: 60,
        alignItems: 'center',
        '& .lastupdate': {
          display: 'inline-block',
          marginRight: 12,
        },
        '& .syncbtn': {
          display: 'inline-block',
          verticalAlign: 'middle',
        },
      },
      '@media (max-width:370px)': {
        '& .lastupdate': {
          verticalAlign: 'middle',
          width: '110px',
        },
      },
    },
    addbtn: {
      '&.infilterbtn': {
        backgroundColor: theme.palette.secondary.main,
        width: 116,
        height: 36,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 100,
        '& svg': {
          stroke: '#fff !important',
        },
        '& button': {
          padding: 10,
        },
        '& button:hover': {
          backgroundColor: 'transparent !important',
        },
      },
    },
    racontainer: {
      backgroundColor: '#fff',
      position: 'absolute',
      right: 0,
      top: 44,
      width: 568,
      height: 184,
      border: '1px solid #C1C1C1',
      boxShadow: '0 0 12px rgba(68, 107, 176, 0.55)',
      borderRadius: 6,
      padding: '26px 40px 0',
      '& label': {
        fontSize: 16,
        color: '#1F1C1C',
        margin: '0 0 22px 0',
        textAlign: 'left',
      },
      '& p': {
        fontSize: 14,
        color: '#949DB2',
        textAlign: 'left',
      },
      '& div.grid': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      '& svg text': {
        stroke: 'transparent',
      },
      '@media (max-width:664px)': {
        right: -46,
        padding: '22px 20px 0',
        width: 310,
        '& p': {
          fontSize: 12,
        },
      },
    },
    proceedbtn: {
      boxShadow: 'none',
      borderRadius: 100,
      width: 116,
      height: 36,
      fontSize: 14,
      textTransform: 'capitalize',
      '@media (max-width:664px)': {
        width: 102,
        height: 32,
        fontSize: 12,
      },
    },
    createbtncontainer: {
      position: 'relative',
      zIndex: 2,
      '@media (max-width:1240px)': {
        zIndex: 0,
      },
    },
  };
};

export default styles;
