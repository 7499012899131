export const ColumnData = {
  product: [
    {
      name: 'description', label: 'PRODUCT NAME', checked: true, fixed: true, sorting: true,
    },
    {
      name: 'sku', label: 'SKU', checked: true, fixed: true, sorting: true,
    },
    {
      name: 'vendor_name', label: 'VENDOR', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'upc', label: 'UPC', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'sold_quantity', label: 'AVAILABLE QTY', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'quantity', label: 'PHYSICAL QTY', checked: true, fixed: false, sorting: true,
    },
  ],
  supplyreceipts: [
    {
      name: 'transaction_at', label: 'DATE', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'purchase_order_no', label: 'P.O. NUMBER', checked: true, fixed: true, sorting: true,
    },
    {
      name: 'vendor', label: 'VENDOR', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'sku', label: 'SKU', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'description', label: 'DESCRIPTION', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'quantity', label: 'QUANTITY RECEIVED', checked: true, fixed: false, sorting: true,
    },
  ],
  vendor_order: [
    {
      name: 'po_no', label: 'PO NUMBER', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'name', label: 'VENDOR NAME', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'freight_forwarder_company', label: 'FREIGHT FORWARDER', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'expected_date', label: 'ARRIVAL DATE', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'status', label: 'STATUS', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'created_by', label: 'SUBMITTED BY', checked: true, fixed: false, sorting: true,
    },
  ],
  allorders: [
    {
      name: 'order_no', label: 'ORDER ID', checked: true, fixed: true, sorting: true,
    },
    {
      name: 'po_no', label: 'REFERENCE ID', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'ordered_at2', label: 'DATE', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'recipient_name', label: 'RECIPIENT NAME', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'order_status_text', label: 'STATUS', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'recipient_email', label: 'EMAIL', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'company_name', label: 'COMPANY NAME', checked: true, fixed: false, sorting: true,
    },
  ],
  pending: [
    {
      name: 'order_no', label: 'ORDER ID', checked: true, fixed: true, sorting: true,
    },
    {
      name: 'po_no', label: 'REFERENCE ID', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'ordered_at2', label: 'DATE', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'recipient_name', label: 'RECIPIENT NAME', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'company_name', label: 'COMPANY NAME', checked: true, fixed: false, sorting: true,
    },
  ],
  shipped: [
    {
      name: 'order_no', label: 'ORDER ID', checked: true, fixed: true, sorting: true,
    },
    {
      name: 'po_no', label: 'REFERENCE ID', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'ordered_at2', label: 'DATE', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'company_name', label: 'COMPANY NAME', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'recipient_name', label: 'RECIPIENT NAME', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'shipped_at2', label: 'SHIPPED DATE', checked: true, fixed: false, sorting: true,
    },
  ],
  returned: [
    {
      name: 'returned_at', label: 'DATE RECEIVED', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'order_no', label: 'ORDER ID', checked: true, fixed: true, sorting: true,
    },
    {
      name: 'recipient_name', label: 'CUSTOMER NAME', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'reason', label: 'RA REASON', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'status', label: 'RA', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'submitted_by', label: 'SUBMITTED BY', checked: true, fixed: false, sorting: false,
    },
  ],
  ra: [
    {
      name: 'returned_at', label: 'RA DATE', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'order_no', label: 'ORDER ID', checked: true, fixed: true, sorting: true,
    },
    {
      name: 'recipient_name', label: 'CUSTOMER NAME', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'reason', label: 'RA REASON', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'status', label: 'STATUS', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'submitted_by', label: 'SUBMITTED BY', checked: true, fixed: false, sorting: true,
    },
  ],
  stockalert: [
    {
      name: 'sku', label: 'SKU', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'description', label: 'PRODUCT NAME', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'min_stock_qty', label: 'MIN STOCK QTY', checked: true, fixed: false, align: 'center', sorting: true,
    },
    {
      name: 'max_stock_qty', label: 'MAX STOCK QTY', checked: true, fixed: false, align: 'center', sorting: true,
    },
    {
      name: 'date', label: 'DATE MODIFIED', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'action', label: 'ACTION', checked: true, fixed: false, align: 'center', sorting: true,
    },
  ],
  thirdparty: [
    {
      name: 'returned_at', label: 'RA DATE', checked: true, fixed: false, sorting: true,
    },
    /* {
      name: 'tracking_no', label: 'TRACKING NO', checked: true, fixed: true, sorting: true,
    }, */
    {
      name: 'recipient_name', label: 'RECIPIENT NAME', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'reason', label: 'RA REASON', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'status', label: 'STATUS', checked: true, fixed: false, sorting: true,
    },
    {
      name: 'submitted_by', label: 'SUBMITTED BY', checked: true, fixed: false, sorting: true,
    },
  ],
};

export const reservedata = { reserve: true };
