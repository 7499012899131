import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Typography } from '@material-ui/core';
import { ReactComponent as Plus } from 'resources/icons/plus.svg';
import styles from './TopBarStyle';

class Addbtn extends Component {
  showModal = (e) => {
    e.stopPropagation();
    const { actions, page } = this.props;
    let modalKey = '';
    let params = {};
    switch (page) {
      case 'supply-receipts':
        params = { type: 'add', action: 'add' };
        modalKey = 'add-supply-receipts';
        actions.ui.openModal({ modalKey, params });
        break;
      case 'orders':
        params = { type: 'add', action: 'add' };
        modalKey = 'modal-order';
        actions.ui.openModal({ modalKey, params });
        break;
      case 'thirdparty':
        params = { type: 'thirdparty', action: 'add' };
        modalKey = 'modal-order';
        actions.ui.openModal({ modalKey, params });
        break;
      default:
        break;
    }
  };

  render() {
    const { classes, searchType, location } = this.props;
    let addTitle = 'Create';
    let showplus = true;
    if (location.pathname === '/third-party') {
      addTitle = 'Add New';
      showplus = false;
    }
    let stype = '';
    if (typeof searchType !== 'undefined') {
      stype = searchType;
    }

    return (
      <div className={`${classes.addbtn} ${stype}btn`} onClick={this.showModal}>
        <IconButton className={classes.iconbtn} aria-label="Add">
          { showplus ?
            <Plus
              className={classes.icon}
            />
          : null }
        </IconButton>
        <Typography className={`${classes.addtitle} ${stype}`} component="span">
          {addTitle}
        </Typography>
      </div>
    );
  }
}

Addbtn.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  actions: PropTypes.instanceOf(Object).isRequired,
  page: PropTypes.string.isRequired,
  searchType: PropTypes.string, // eslint-disable-line
  location: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(Addbtn);
