import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
import Modal from 'ui/components/modal/Modal';
import DrawerContainer from 'ui/components/modal/DrawerContainer';
import PageLoader from 'ui/components/loader/PageLoader';
import TopBar from 'ui/components/common/wms/TopBar';
import AllFilters from 'ui/components/common/wms/AllFilters';
import { NavLink } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ColumnData } from 'ui/components/common/wms/MenuList';
import HeaderDropMenu from 'ui/components/common/wms/HeaderDropMenu';
import OrderFilterForm from './OrderFilterForm';
import OrderDetailContainer from './OrderDetailContainer';
import styles from './OrdersStyle';

const Loading = () => <div>&nbsp;</div>;

const AllOrders = Loadable({
  loader: () => import(/* webpackChunkName: "all_orders" */'./Listing'),
  loading: Loading,
});

const PendingListing = Loadable({
  loader: () => import(/* webpackChunkName: "pending_orders" */'./PendingListing'),
  loading: Loading,
});

const ShippedListing = Loadable({
  loader: () => import(/* webpackChunkName: "shipped_orders" */'./ShippedListing'),
  loading: Loading,
});

const ReturnedListing = Loadable({
  loader: () => import(/* webpackChunkName: "returned_orders" */'./ReturnedListing'),
  loading: Loading,
});

const ReturnAuthorizationListing = Loadable({
  loader: () => import(/* webpackChunkName: "returned_authorization" */'./ReturnAuthorizationListing'),
  loading: Loading,
});

const ThirdPartyListing = Loadable({
  loader: () => import(/* webpackChunkName: "third_party" */'./ThirdPartyListing'),
  loading: Loading,
});

const AddEditOrder = Loadable({
  loader: () => import(/* webpackChunkName: "add_edit_order" */'./AddEditOrder'),
  loading: Loading,
});

const AddEditThirdParty = Loadable({
  loader: () => import(/* webpackChunkName: "add_edit_third_party" */'./AddEditThirdParty'),
  loading: Loading,
});

const Returned = Loadable({
  loader: () => import(/* webpackChunkName: "returned_order" */'./Returned/Returned'),
  loading: Loading,
});

const CreateRA = Loadable({
  loader: () => import(/* webpackChunkName: "create_ra" */'./CreateRA'),
  loading: Loading,
});

const ReviewSFOrder = Loadable({
  loader: () => import(/* webpackChunkName: "review_sf_order" */'./ReviewSFOrder'),
  loading: Loading,
});

const ReviewThirdParty = Loadable({
  loader: () => import(/* webpackChunkName: "review_third_party_order" */'./ReviewThirdParty'),
  loading: Loading,
});

class Orders extends Component {
  state = {
    limit: 20,
    page: 1,
    searchtxt: '',
  }
  componentWillMount() {
    this.searchFilter();
    const { actions } = this.props;
    const modalKey = 'modal-order';
    const params = { type: '' };
    actions.ui.closeModal({ modalKey, params });
    actions.ui.closeDrawer();
  }
  componentDidUpdate() {
    const { getloadtype, setloadtype, actionloading } = this.props;
    if (getloadtype === 'filter updated') {
      setloadtype('');
      this.updateFilter();
    }
    if (!actionloading && getloadtype === 'getclients') {
      this.searchFilter();
      setloadtype('search');
    }
  }
  onSearch = (e) => {
    if (e.charCode === 13) {
      const txt = e.target.value.trim();
      this.searchFilter(txt);
    }
  }
  onSearchClose = () => {
    const { searchtxt } = this.state;
    if (searchtxt !== '') {
      this.searchFilter('');
    }
  }
  updateFilter = () => {
    this.searchFilter();
  }
  searchFilter = (txt) => {
    const { limit, page, searchtxt } = this.state;
    const {
      location, orderList, returnOrderList, thirdPartyList, setloadtype, filtersdata,
      setSortColumn, setSortType, clientId,
    } = this.props;
    let stxt = txt;
    if (typeof txt === 'undefined') {
      stxt = searchtxt;
    }
    let recipientName = '';
    let startDate = '';
    let endDate = '';
    let typeId = -1;
    let carrierCode = '';
    let companyName = '';
    let emailAdd = '';

    if (filtersdata.length) {
      for (const key in filtersdata) { // eslint-disable-line
        if (filtersdata[key].id === 1) {
          recipientName = filtersdata[key].recipient_name;
        }
        if (filtersdata[key].id === 2) {
          startDate = filtersdata[key].order_date_start;
        }
        if (filtersdata[key].id === 3) {
          endDate = filtersdata[key].order_date_end;
        }
        if (filtersdata[key].id === 4) {
          typeId = filtersdata[key].type;
        }
        if (filtersdata[key].id === 5) {
          carrierCode = filtersdata[key].carrier_code;
        }
        if (filtersdata[key].id === 8) {
          recipientName = filtersdata[key].recipient_name;
          companyName = filtersdata[key].company_name;
          emailAdd = filtersdata[key].recipient_email;
        }
      }
    }
    let proceed = true;
    if (startDate !== '' && endDate === '') {
      proceed = false;
    }
    if (startDate === '' && endDate !== '') {
      proceed = false;
    }
    const cid = clientId === 0 ? null : clientId;
    switch (location.pathname) {
      case '/order-fulfillment':
        if (proceed) {
          orderList(cid, typeId, limit, page, stxt, startDate, endDate, recipientName, carrierCode, companyName, emailAdd, 'ordered_at', 'desc');
          setSortColumn('ordered_at2');
          setSortType('desc');
        }
        break;
      case '/pending-orders':
        if (proceed) {
          orderList(cid, 1, limit, page, stxt, startDate, endDate, recipientName, carrierCode, companyName, emailAdd, 'ordered_at', 'desc');
          setSortColumn('ordered_at2');
          setSortType('desc');
        }
        break;
      case '/shipped-orders':
        if (proceed) {
          orderList(cid, 4, limit, page, stxt, startDate, endDate, recipientName, carrierCode, companyName, emailAdd, 'ordered_at', 'desc');
          setSortColumn('ordered_at2');
          setSortType('desc');
        }
        break;
      case '/returns':
      case '/returned-orders':
        returnOrderList(
          cid, 1, limit, page, stxt, startDate, endDate, recipientName,
          companyName, emailAdd, 'returned_at', 'desc',
        );
        setSortColumn('returned_at');
        setSortType('desc');
        proceed = true;
        break;
      case '/return-authorization':
        returnOrderList(
          cid, -1, limit, page, stxt, startDate, endDate, recipientName,
          companyName, emailAdd, 'returned_at', 'desc',
        );
        setSortColumn('returned_at');
        setSortType('desc');
        proceed = true;
        break;
      case '/third-party':
        thirdPartyList(
          cid, -1, limit, page, stxt, startDate, endDate, recipientName,
          companyName, emailAdd, 'returned_at', 'desc',
        );
        setSortColumn('returned_at');
        setSortType('desc');
        proceed = true;
        break;
      default:
        break;
    }
    if (proceed) {
      if (typeof txt !== 'undefined') {
        this.setState({
          ...this.state,
          searchtxt: txt,
        });
      }
      setloadtype('search');
    }
  }
  handleSorting = (order, orderBy) => {
    this.child.sortOrders(order, orderBy);
  }

  render() {
    const { loading, classes, ...others } = this.props;
    if (loading) {
      return (
        <Fragment>
          <PageLoader />
        </Fragment>
      );
    }
    const {
      actions,
      modal,
      location,
      settings,
      usersettings,
      drawer,
    } = this.props;

    const { searchtxt } = this.state;
    const modtype = typeof modal.params !== 'undefined' ? modal.params.type : '';
    const drawertype = typeof drawer.params !== 'undefined' ? drawer.params.type : '';
    const initsettings = (typeof usersettings !== 'undefined' && usersettings !== 'null') ? usersettings : ColumnData;
    let menus = settings !== null ? settings : initsettings;
    menus = ColumnData;

    const loc = location.pathname.split('/', 4, 0);
    const page = loc[1];
    let rturns = false;
    let pagefor = 'orders';
    switch (page) {
      case 'returned-orders':
      case 'return-authorization':
      case 'third-party':
        rturns = true;
        pagefor = 'thirdparty';
        break;
      default:
        break;
    }
    return (
      <Fragment>
        { rturns ?
          <div className={classes.btnswrapper}>
            <Button component={NavLink} to="/returned-orders" className={`${page === 'returned-orders' ? 'active' : ''} ${classes.returnbtn}`}>Returned</Button>
            <Button component={NavLink} to="/return-authorization" className={`${page === 'return-authorization' ? 'active' : ''} ${classes.returnbtn}`}>RA</Button>
            <Button component={NavLink} to="/third-party" className={`${page === 'third-party' ? 'active' : ''} ${classes.returnbtn}`}>3rd Party</Button>
          </div>
        : null }
        <div className={classes.headdropmobile}>
          <HeaderDropMenu {...others} />
        </div>
        <TopBar
          menus={menus}
          page="orders"
          {...others}
          sfc
          onSearch={e => this.onSearch(e)}
          onSearchClose={this.onSearchClose}
        >
          <OrderFilterForm {...others} />
        </TopBar>
        <AllFilters
          {...others}
          sfc
          page={pagefor}
          onSearch={e => this.onSearch(e)}
          onSearchClose={this.onSearchClose}
          onRequestSort={this.handleSorting}
        />
        { location.pathname === '/order-fulfillment' ?
          <AllOrders
            {...others}
            menus={menus}
            searchtxt={searchtxt}
            onRef={(instance) => { this.child = instance; }}
          />
        : null }
        { location.pathname === '/pending-orders' ?
          <PendingListing
            {...others}
            menus={menus}
            searchtxt={searchtxt}
            onRef={(instance) => { this.child = instance; }}
          />
        : null }
        { location.pathname === '/shipped-orders' ?
          <ShippedListing
            {...others}
            menus={menus}
            searchtxt={searchtxt}
            onRef={(instance) => { this.child = instance; }}
          />
        : null }
        { location.pathname === '/returned-orders' || location.pathname === '/returns' ?
          <ReturnedListing
            {...others}
            menus={menus}
            searchtxt={searchtxt}
            onRef={(instance) => { this.child = instance; }}
          />
        : null }
        { location.pathname === '/return-authorization' ?
          <ReturnAuthorizationListing
            {...others}
            menus={menus}
            searchtxt={searchtxt}
            onRef={(instance) => { this.child = instance; }}
          />
        : null }
        { location.pathname === '/third-party' ?
          <ThirdPartyListing
            {...others}
            menus={menus}
            searchtxt={searchtxt}
            onRef={(instance) => { this.child = instance; }}
          />
        : null }

        <DrawerContainer
          {...others}
        >
          { drawertype === 'order details' ?
            <OrderDetailContainer {...others} />
          :
            <>&nbsp;</>
          }
        </DrawerContainer>
        <Modal
          modalKey="modal-order"
          modalState={modal}
          closeAction={actions.ui.closeModal}
        >
          { modtype === 'add' ?
            <AddEditOrder {...others} />
          : null }

          { modtype === 'thirdparty' ?
            <AddEditThirdParty {...others} />
          : null }

          { modtype === 'returned' ?
            <Returned {...others} />
          : null }

          { modtype === 'create' ?
            <CreateRA {...others} />
          : null }

          { modtype === 'review' ?
            <ReviewSFOrder {...others} />
          : null }

          { modtype === 'reviewthirdparty' ?
            <ReviewThirdParty {...others} />
          : null }
        </Modal>
      </Fragment>
    );
  }
}

Orders.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
  actionloading: PropTypes.bool.isRequired,
  orderList: PropTypes.func.isRequired,
  setSortColumn: PropTypes.func.isRequired,
  setSortType: PropTypes.func.isRequired,
  actions: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  filtersdata: PropTypes.instanceOf(Object).isRequired,
  clientId: PropTypes.number.isRequired,
};

export default withStyles(styles)(Orders);

