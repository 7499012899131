import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MoreOrderFilterForm from 'ui/pages/wms/orders/MoreOrderFilterForm';
import OrderDateFilterForm from 'ui/pages/wms/orders/OrderDateFilterForm';
import Searchbtn from './Searchbtn';
import Addbtn from './Addbtn';
/* import Createbtn from './Createbtn'; */
import MoreFilters from './MoreFilters';
import StatusList from './StatusList';
/* import Sorting from './Sorting'; */
/* import UpdateSyncBtn from './UpdateSyncBtn'; */
import styles from './AllFiltersStyle';

class AllFilters extends Component {
  triggerSearch = (e) => {
    if (e.charCode === 13) {
      const { onSearch } = this.props;
      onSearch(e);
    }
  }
  triggerSearchClose = () => {
    const { onSearchClose } = this.props;
    onSearchClose();
  }

  handleSorting = (order, orderBy) => {
    this.props.onRequestSort(order, orderBy);
  };

  render() {
    const { classes, ...other } = this.props;
    const { location } = this.props;
    let showstatus = true;
    let createbtn = false;
    let showcreatebtn = true;
    switch (location.pathname) {
      case '/pending-orders':
      case '/shipped-orders':
        showstatus = false;
        showcreatebtn = false;
        break;
      case '/returned-orders':
      case '/return-authorization':
        showstatus = false;
        createbtn = true;
        break;
      case '/third-party':
        showstatus = false;
        break;
      default:
        break;
    }

    return (
      <div className={classes.root}>
        {/* <div className={classes.updatebtn}>
          <UpdateSyncBtn displayfor="desktop" {...other} />
        </div> */}
        <ul className="ulfilterlist">
          <li className="lifilterlist">
            <div className="sbox">
              <Searchbtn
                {...other}
                searchType="infilter"
                triggerSearch={e => this.triggerSearch(e)}
                triggerSearchClose={this.triggerSearchClose}
              />
            </div>
          </li>
          { showstatus ?
            <li className="lifilterlist">
              <div className="btn status-btn">
                <StatusList {...other} />
              </div>
            </li>
          : null }
          <li className="lifilterlist">
            <div className="btn">
              <MoreFilters header="Date" title="Date" {...other}>
                <OrderDateFilterForm {...other} />
              </MoreFilters>
            </div>
          </li>
          <li className="lifilterlist">
            <div className="btn">
              <MoreFilters header="Filters" title="More Filters" {...other}>
                <MoreOrderFilterForm {...other} />
              </MoreFilters>
            </div>
          </li>
          <li className="lifilterlist">
            <div className="sdiv">
              {/* <Sorting {...other} onRequestSort={this.handleSorting} /> */}
            </div>
            <div className={classes.add}>
              { !createbtn ?
                <>
                  { showcreatebtn ?
                    <Addbtn searchType="infilter" {...other} />
                  : null }
                </>
              : null }
              {/* { createbtn ?
                <Createbtn searchType="infilter" {...other} />
              : null } */}
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

AllFilters.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  onSearch: PropTypes.func, // eslint-disable-line
  onSearchClose: PropTypes.func, // eslint-disable-line
  onRequestSort: PropTypes.func, // eslint-disable-line
  location: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(AllFilters);
