/* eslint-disable no-console */
import {
  requestOrders,
  requestOrderDetails,
  requestReturnOrders,
  requestOrderItems,
  requestVendorOrders,
  requestVendorOrderDetails,
  requestCarrierService,
  postSalesOrder,
  postReturnOrders,
  saveReturnOrders,
  createReturnOrders,
  postOrderRecipient,
  autoSearchRecipient,
  importWMSOrders,
  lastImportOrders,
  importWMSOrdersById,
  autoSearchClient,
  autoSearchOrders,
  requestThirdParty,
  requestThirdPartyForm,
  postThirdPartyType,
  requestThirdPartyDetails,
  postThirdPartyReturn,
} from 'api/orders';
import { showActionLoading, hideActionLoading, showLoading, hideLoading, setLoadType } from 'state/loading/actions';
import responseCheker from 'api/responseCheker';
import { openAlertModal } from 'state/modal-drawer/actions';

import {
  SET_ORDERS_DATA,
  SET_MORE_ORDERS_DATA,
  SET_ORDER_DETAILS_DATA,
  RESET_ORDERS_DATA,
  SET_RA_DATA,
  SET_EDIT_DATA,
  SET_RECIPIENT_DATA,
  SET_CARRIER_SERVICE_DATA,
  SET_ORDER_INFO_DATA,
  SET_RETURNED_ORDERS_DATA,
  SET_MORE_RETURNED_ORDERS_DATA,
  SET_FILTERS_DATA,
  SET_RECIPIENT_AUTOCOMPLETE_DATA,
  RESET_RECIPIENT_AUTOCOMPLETE_DATA,
  SET_VENDOR_ORDERS_DATA,
  SET_MORE_VENDOR_ORDERS_DATA,
  SET_IMPORT_INFO_DATA,
  SET_IMPORT_ORDERS_DATA,
  SET_SORT_COLUMN,
  SET_SORT_TYPE,
  SET_CLIENT_AUTOCOMPLETE_DATA,
  RESET_CLIENT_AUTOCOMPLETE_DATA,
  SET_ORDERS_AUTOCOMPLETE_DATA,
  RESET_ORDERS_AUTOCOMPLETE_DATA,
  SET_THIRD_PARTY_FORM_DATA,
  SET_THIRD_PARTY_TYPE,
} from './types';

export const setOrders = orders => ({
  type: SET_ORDERS_DATA,
  payload: { orders },
});

export const setMoreOrders = orders => ({
  type: SET_MORE_ORDERS_DATA,
  payload: { orders },
});

export const setVendorOrders = vendororders => ({
  type: SET_VENDOR_ORDERS_DATA,
  payload: { vendororders },
});

export const setMoreVendorOrders = vendororders => ({
  type: SET_MORE_VENDOR_ORDERS_DATA,
  payload: { vendororders },
});

export const setReturnedOrders = returnedorders => ({
  type: SET_RETURNED_ORDERS_DATA,
  payload: { returnedorders },
});

export const setMoreReturnedOrders = returnedorders => ({
  type: SET_MORE_RETURNED_ORDERS_DATA,
  payload: { returnedorders },
});

export const setOrderDetails = detail => ({
  type: SET_ORDER_DETAILS_DATA,
  payload: { detail },
});

export const resetOrders = () => ({
  type: RESET_ORDERS_DATA,
});

export const setRAData = radata => ({
  type: SET_RA_DATA,
  payload: { radata },
});

export const setDataEntry = dataentry => ({
  type: SET_EDIT_DATA,
  payload: { dataentry },
});

export const setRecipient = recipientdata => ({
  type: SET_RECIPIENT_DATA,
  payload: { recipientdata },
});

export const setCarrierService = carrierdata => ({
  type: SET_CARRIER_SERVICE_DATA,
  payload: { carrierdata },
});

export const setOrderInfo = orderinfo => ({
  type: SET_ORDER_INFO_DATA,
  payload: { orderinfo },
});

export const setFilters = filters => ({
  type: SET_FILTERS_DATA,
  payload: { filters },
});

export const setRecipientAutocomplete = autocompleteRecipient => ({
  type: SET_RECIPIENT_AUTOCOMPLETE_DATA,
  payload: { autocompleteRecipient },
});

export const resetRecipientAutocomplete = () => ({
  type: RESET_RECIPIENT_AUTOCOMPLETE_DATA,
});

export const setImportInfo = importinfo => ({
  type: SET_IMPORT_INFO_DATA,
  payload: { importinfo },
});

export const setImportOrders = importorders => ({
  type: SET_IMPORT_ORDERS_DATA,
  payload: { importorders },
});

export const setSortColumn = sortColumn => ({
  type: SET_SORT_COLUMN,
  payload: { sortColumn },
});

export const setSortType = sortType => ({
  type: SET_SORT_TYPE,
  payload: { sortType },
});

export const setClientAutocomplete = autocompleteClient => ({
  type: SET_CLIENT_AUTOCOMPLETE_DATA,
  payload: { autocompleteClient },
});

export const resetClientAutocomplete = () => ({
  type: RESET_CLIENT_AUTOCOMPLETE_DATA,
});

export const setOrdersAutocomplete = autocompleteOrders => ({
  type: SET_ORDERS_AUTOCOMPLETE_DATA,
  payload: { autocompleteOrders },
});

export const resetOrderAutocomplete = () => ({
  type: RESET_ORDERS_AUTOCOMPLETE_DATA,
});

export const setThirdPartyForm = thirdpartformdata => ({
  type: SET_THIRD_PARTY_FORM_DATA,
  payload: { thirdpartformdata },
});

export const setThirdPartyType = thirdpartytype => ({
  type: SET_THIRD_PARTY_TYPE,
  payload: { thirdpartytype },
});

export const successResponse = (response, type) => (dispatch) => {
  dispatch(responseCheker(response));
  let cond = type;
  if (response) {
    if (cond === 'list' || cond === 'slist') {
      dispatch(setOrders(response.data));
    }
    if (cond === 'morelist') {
      dispatch(setMoreOrders(response.data));
    }
    if (cond === 'vlist' || cond === 'vslist') {
      dispatch(setVendorOrders(response.data));
    }
    if (cond === 'vmorelist') {
      dispatch(setMoreVendorOrders(response.data));
    }
    if (cond === 'returnlist' || cond === 'sreturnlist') {
      dispatch(setReturnedOrders(response.data));
    }
    if (cond === 'morereturnlist') {
      dispatch(setMoreReturnedOrders(response.data));
    }
    if (cond === '3rdpartylist' || cond === 's3rdpartylist') {
      dispatch(setReturnedOrders(response.data));
    }
    if (cond === 'more3rdpartylist') {
      dispatch(setMoreReturnedOrders(response.data));
    }
    if (cond === 'detail') {
      dispatch(setOrderDetails(response.data));
      dispatch(setDataEntry(response.data.attributes));
      dispatch(setRAData(response.data.attributes));
    }
    if (cond === 'addrecipient') {
      dispatch(setRecipient(response.data.data));
    }
    if (cond === 'autocomplete') {
      dispatch(setRecipientAutocomplete(response.data.data));
    }
    if (cond === 'importsinfo') {
      dispatch(setImportInfo(response.data));
    }
    if (cond === 'importorders') {
      dispatch(setImportOrders(response.data));
    }
    if (cond === 'autocompleteclient') {
      dispatch(setClientAutocomplete(response.data.data));
    }
    if (cond === 'autocompleteorder') {
      dispatch(setOrdersAutocomplete(response.data.data));
    }
    if (cond === 'carrires') {
      dispatch(setCarrierService(response.data.data.attributes));
    }
    if (cond === '3rdpartyform') {
      dispatch(setThirdPartyForm(response.data.data.attributes));
    }
    if (cond === 'add3rdpartytype') {
      dispatch(setThirdPartyType(response.data.data));
    }
  }

  if (cond === 'slist'
    || cond === 'morelist'
    || cond === 'sreturnlist'
    || cond === '3rdpartylist'
    || cond === 's3rdpartylist'
    || cond === 'addsalesorder'
    || cond === 'addthirdparty'
    || cond === 'returnorders'
    || cond === 'updateorders'
    || cond === 'addrecipient'
    || cond === 'autocomplete'
    || cond === 'autocompleteclient'
    || cond === 'importsinfo'
    || cond === 'importorders'
    || cond === 'update_sales_orders'
    || cond === 'autocompleteorder'
    || cond === 'carrires'
    || cond === '3rdpartyform'
    || cond === 'add3rdpartytype'
    || cond === 'detail') {
    cond = false;
  }

  if (cond) {
    dispatch(hideLoading());
  }
  dispatch(hideActionLoading());
};

export const errorResponse = (err, type = true, bypassError = false) => (dispatch) => {
  const result = dispatch(responseCheker(err.response));
  if (result === 'error') {
    if (!bypassError) {
      const modalKey = 'alert';
      const params = {
        type: 'error',
        title: '',
        description: err.response.data.errors[0].detail,
      };
      dispatch(openAlertModal({ modalKey, params }));
      dispatch(setLoadType('msg error'));
    } else {
      dispatch(setLoadType(err.response.data.errors[0].detail));
    }
  }
  if (type) {
    dispatch(hideLoading());
  }
  dispatch(hideActionLoading());
};

export const addSalesOrder = (dataToSubmit, id = null) => (dispatch) => {
  dispatch(showActionLoading());
  postSalesOrder(dataToSubmit, id).then(
    response => dispatch(successResponse(response, 'addsalesorder')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const addReturnOrders = dataToSubmit => (dispatch) => {
  dispatch(showActionLoading());
  postReturnOrders(dataToSubmit).then(
    response => dispatch(successResponse(response, 'returnorders')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const updateReturnOrders = (id, dataToSubmit) => (dispatch) => {
  dispatch(showActionLoading());
  saveReturnOrders(id, dataToSubmit).then(
    response => dispatch(successResponse(response, 'returnorders')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const updateOrders = dataToSubmit => (dispatch) => {
  dispatch(showActionLoading());
  createReturnOrders(dataToSubmit).then(
    response => dispatch(successResponse(response, 'updateorders')),
    err => dispatch(errorResponse(err, false)),
  );
};

/* mock data quuery */

/* export const fetchOrderList = () => dispatch => new Promise((resolve) => {
  dispatch(showActionLoading());
  requestOrders().then((response) => {
    response.json().then((json) => {
      dispatch(hideActionLoading());
      if (response.ok) {
        dispatch(successResponse({ data: json.payload }, 'list'));
        resolve(json.payload);
      } else {
        console.log('error: ', json.errors.map(err => err.message).join(', '));
        resolve();
      }
    });
  });
}); */

/* export const fetchReturnOrderList = () => dispatch => new Promise((resolve) => {
  dispatch(showActionLoading());
  requestReturnOrders().then((response) => {
    response.json().then((json) => {
      dispatch(hideActionLoading());
      if (response.ok) {
        dispatch(successResponse({ data: json.payload }, 'returnlist'));
        resolve(json.payload);
      } else {
        console.log('error: ', json.errors.map(err => err.message).join(', '));
        resolve();
      }
    });
  });
}); */

/* end mock data query */

export const fetchOrderList = (
  clientId, type, limit, page, text, start, end, name, code, company, email, sortColumn,
  sortType, exceptOrderStatusNew,
) => (dispatch) => {
  let cond = 'list';
  let txt = null;
  let dstart = null;
  let dend = null;
  let cname = null;
  let ccode = null;
  let ccompany = null;
  let cemail = null;
  let eosnew = null;
  let loadAction = false;
  if (typeof start !== 'undefined') {
    dstart = start;
    if (start === '') {
      dstart = null;
    }
    loadAction = true;
  }
  if (typeof end !== 'undefined') {
    dend = end;
    if (end === '') {
      dend = null;
    }
    loadAction = true;
  }
  if (typeof name !== 'undefined') {
    cname = name;
    if (name === '') {
      cname = null;
    }
    loadAction = true;
  }
  if (typeof code !== 'undefined') {
    ccode = code;
    if (code === '') {
      ccode = null;
    }
    loadAction = true;
  }
  if (typeof company !== 'undefined') {
    ccompany = company;
    if (company === '') {
      ccompany = null;
    }
    loadAction = true;
  }
  if (typeof email !== 'undefined') {
    cemail = email;
    if (email === '') {
      cemail = null;
    }
    loadAction = true;
  }
  if (typeof text !== 'undefined') {
    txt = text;
    if (text === '') {
      txt = null;
    }
    cond = 'slist';
    loadAction = true;
  }
  if (typeof exceptOrderStatusNew !== 'undefined') {
    eosnew = exceptOrderStatusNew;
    if (exceptOrderStatusNew === '') {
      eosnew = null;
    }
    loadAction = true;
  }
  if (loadAction) {
    dispatch(showActionLoading());
  } else {
    dispatch(showLoading());
  }
  requestOrders(
    clientId, type, limit, page, txt, dstart, dend, cname, ccode, ccompany, cemail, sortColumn,
    sortType, eosnew,
  ).then(
    response => dispatch(successResponse(response, cond)),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchMoreOrderList = (
  clientId, type, limit, page, text, start, end, name, code, company, email, sortColumn,
  sortType, exceptOrderStatusNew,
) => (dispatch) => {
  let txt = null;
  let dstart = null;
  let dend = null;
  let cname = null;
  let ccode = null;
  let ccompany = null;
  let cemail = null;
  let eosnew = null;
  if (typeof start !== 'undefined') {
    dstart = start;
    if (start === '') {
      dstart = null;
    }
  }
  if (typeof end !== 'undefined') {
    dend = end;
    if (end === '') {
      dend = null;
    }
  }
  if (typeof name !== 'undefined') {
    cname = name;
    if (name === '') {
      cname = null;
    }
  }
  if (typeof code !== 'undefined') {
    ccode = code;
    if (code === '') {
      ccode = null;
    }
  }
  if (typeof company !== 'undefined') {
    ccompany = company;
    if (company === '') {
      ccompany = null;
    }
  }
  if (typeof email !== 'undefined') {
    cemail = email;
    if (email === '') {
      cemail = null;
    }
  }
  if (typeof text !== 'undefined') {
    txt = text;
    if (text === '') {
      txt = null;
    }
  }
  if (typeof exceptOrderStatusNew !== 'undefined') {
    eosnew = exceptOrderStatusNew;
    if (exceptOrderStatusNew === '') {
      eosnew = null;
    }
  }
  dispatch(showActionLoading());
  requestOrders(
    clientId, type, limit, page, txt, dstart, dend, cname, ccode, ccompany, cemail, sortColumn,
    sortType, eosnew,
  ).then(
    response => dispatch(successResponse(response, 'morelist')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchReturnOrderList = (
  id, status, limit, page, text, start, end, name, company, email,
  sortColumn, sortType,
) => (dispatch) => {
  let cond = 'returnlist';
  let txt = null;
  let dstart = null;
  let dend = null;
  let cname = null;
  let ccompany = null;
  let cemail = null;
  let loadAction = false;
  if (typeof start !== 'undefined') {
    dstart = start;
    if (start === '') {
      dstart = null;
    }
    loadAction = true;
  }
  if (typeof end !== 'undefined') {
    dend = end;
    if (end === '') {
      dend = null;
    }
    loadAction = true;
  }
  if (typeof name !== 'undefined') {
    cname = name;
    if (name === '') {
      cname = null;
    }
    loadAction = true;
  }
  if (typeof company !== 'undefined') {
    ccompany = company;
    if (company === '') {
      ccompany = null;
    }
    loadAction = true;
  }
  if (typeof email !== 'undefined') {
    cemail = email;
    if (email === '') {
      cemail = null;
    }
    loadAction = true;
  }
  if (typeof text !== 'undefined') {
    txt = text;
    if (text === '') {
      txt = null;
    }
    cond = 'sreturnlist';
    loadAction = true;
  }
  if (loadAction) {
    dispatch(showActionLoading());
  } else {
    dispatch(showLoading());
  }
  requestReturnOrders(
    id, status, limit, page, txt, dstart, dend, cname, ccompany, cemail,
    sortColumn, sortType,
  ).then(
    response => dispatch(successResponse(response, cond)),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchMoreReturnOrderList = (
  id, status, limit, page, text, start, end, name, company, email,
  sortColumn, sortType,
) => (dispatch) => {
  let txt = null;
  let dstart = null;
  let dend = null;
  let cname = null;
  let ccompany = null;
  let cemail = null;
  if (typeof start !== 'undefined') {
    dstart = start;
    if (start === '') {
      dstart = null;
    }
  }
  if (typeof end !== 'undefined') {
    dend = end;
    if (end === '') {
      dend = null;
    }
  }
  if (typeof name !== 'undefined') {
    cname = name;
    if (name === '') {
      cname = null;
    }
  }
  if (typeof company !== 'undefined') {
    ccompany = company;
    if (company === '') {
      ccompany = null;
    }
  }
  if (typeof email !== 'undefined') {
    cemail = email;
    if (email === '') {
      cemail = null;
    }
  }
  if (typeof text !== 'undefined') {
    txt = text;
    if (text === '') {
      txt = null;
    }
  }
  dispatch(showActionLoading());
  requestReturnOrders(
    id, status, limit, page, txt, dstart, dend, cname, ccompany, cemail,
    sortColumn, sortType,
  ).then(
    response => dispatch(successResponse(response, 'morereturnlist')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchVendorOrderList = (id, limit, page, text, sortColumn, sortType) => (dispatch) => {
  let cond = 'vlist';
  let txt = null;
  if (typeof text !== 'undefined') {
    dispatch(showActionLoading());
    txt = text;
    if (text === '') {
      txt = null;
    }
    cond = 'vslist';
  } else {
    dispatch(showLoading());
  }
  requestVendorOrders(id, limit, page, txt, sortColumn, sortType).then(
    response => dispatch(successResponse(response, cond)),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchMoreVendorOrderList = (
  id, limit, page, text,
  sortColumn, sortType,
) => (dispatch) => {
  let txt = null;
  if (typeof text !== 'undefined') {
    txt = text;
    if (text === '') {
      txt = null;
    }
  }
  dispatch(showActionLoading());
  requestVendorOrders(id, limit, page, txt, sortColumn, sortType).then(
    response => dispatch(successResponse(response, 'vmorelist')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchOrderDetail = id => (dispatch) => {
  dispatch(showActionLoading());
  requestOrderDetails(id).then(
    response => dispatch(successResponse(response, 'detail')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchOrderItems = ranumber => (dispatch) => {
  dispatch(showActionLoading());
  requestOrderItems(ranumber).then(
    response => dispatch(successResponse(response, 'detail')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchVendorOrderDetails = ponumber => (dispatch) => {
  dispatch(showActionLoading());
  requestVendorOrderDetails(ponumber).then(
    response => dispatch(successResponse(response, 'detail')),
    err => dispatch(errorResponse(err)),
  );
};

export const getCarrierService = () => (dispatch) => {
  dispatch(showActionLoading());
  requestCarrierService().then(
    response => dispatch(successResponse(response, 'carrires')),
    err => dispatch(errorResponse(err)),
  );
};

export const addOrderRecipient = dataToSubmit => (dispatch) => {
  dispatch(showActionLoading());
  postOrderRecipient(dataToSubmit).then(
    response => dispatch(successResponse(response, 'addrecipient')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const autocompleteRecipient = (clientId, txt, limit = 20, page = 1) => (dispatch) => {
  dispatch(showActionLoading());
  autoSearchRecipient(clientId, txt, limit, page).then(
    response => dispatch(successResponse(response, 'autocomplete')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const importsInfo = () => (dispatch) => {
  dispatch(showActionLoading());
  lastImportOrders().then(
    response => dispatch(successResponse(response, 'importsinfo')),
    err => dispatch(errorResponse(err, false, true)),
  );
};

export const importOrders = () => (dispatch) => {
  dispatch(showActionLoading());
  importWMSOrders().then(
    response => dispatch(successResponse(response, 'importorders')),
    err => dispatch(errorResponse(err, false, true)),
  );
};

export const updateSalesOrder = dataToSubmit => (dispatch) => {
  dispatch(showActionLoading());
  importWMSOrdersById(dataToSubmit).then(
    response => dispatch(successResponse(response, 'update_sales_orders')),
    err => dispatch(errorResponse(err, false, true)),
  );
};

export const autocompleteClient = (txt, limit = 20, page = 1) => (dispatch) => {
  dispatch(showActionLoading());
  autoSearchClient(txt, limit, page).then(
    response => dispatch(successResponse(response, 'autocompleteclient')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const autocompleteOrder = (txt, status = null, limit = 20, page = 1) => (dispatch) => {
  dispatch(showActionLoading());
  autoSearchOrders(txt, status, limit, page).then(
    response => dispatch(successResponse(response, 'autocompleteorder')),
    err => dispatch(errorResponse(err, false)),
  );
};

// third party

export const fetchThirdPartyList = (
  id, status, limit, page, text, start, end, name, company, email,
  sortColumn, sortType,
) => (dispatch) => {
  let cond = '3rdpartylist';
  let txt = null;
  let dstart = null;
  let dend = null;
  let cname = null;
  let ccompany = null;
  let cemail = null;
  let loadAction = false;
  if (typeof start !== 'undefined') {
    dstart = start;
    if (start === '') {
      dstart = null;
    }
    loadAction = true;
  }
  if (typeof end !== 'undefined') {
    dend = end;
    if (end === '') {
      dend = null;
    }
    loadAction = true;
  }
  if (typeof name !== 'undefined') {
    cname = name;
    if (name === '') {
      cname = null;
    }
    loadAction = true;
  }
  if (typeof company !== 'undefined') {
    ccompany = company;
    if (company === '') {
      ccompany = null;
    }
    loadAction = true;
  }
  if (typeof email !== 'undefined') {
    cemail = email;
    if (email === '') {
      cemail = null;
    }
    loadAction = true;
  }
  if (typeof text !== 'undefined') {
    txt = text;
    if (text === '') {
      txt = null;
    }
    cond = 's3rdpartylist';
    loadAction = true;
  }
  if (loadAction) {
    dispatch(showActionLoading());
  } else {
    dispatch(showLoading());
  }
  requestThirdParty(
    id, status, limit, page, txt, dstart, dend, cname, ccompany, cemail,
    sortColumn, sortType,
  ).then(
    response => dispatch(successResponse(response, cond)),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchMoreThirdPartyList = (
  id, status, limit, page, text, start, end, name, company, email,
  sortColumn, sortType,
) => (dispatch) => {
  let txt = null;
  let dstart = null;
  let dend = null;
  let cname = null;
  let ccompany = null;
  let cemail = null;
  if (typeof start !== 'undefined') {
    dstart = start;
    if (start === '') {
      dstart = null;
    }
  }
  if (typeof end !== 'undefined') {
    dend = end;
    if (end === '') {
      dend = null;
    }
  }
  if (typeof name !== 'undefined') {
    cname = name;
    if (name === '') {
      cname = null;
    }
  }
  if (typeof company !== 'undefined') {
    ccompany = company;
    if (company === '') {
      ccompany = null;
    }
  }
  if (typeof email !== 'undefined') {
    cemail = email;
    if (email === '') {
      cemail = null;
    }
  }
  if (typeof text !== 'undefined') {
    txt = text;
    if (text === '') {
      txt = null;
    }
  }
  dispatch(showActionLoading());
  requestThirdParty(
    id, status, limit, page, txt, dstart, dend, cname, ccompany, cemail,
    sortColumn, sortType,
  ).then(
    response => dispatch(successResponse(response, 'more3rdpartylist')),
    err => dispatch(errorResponse(err)),
  );
};

export const getThirdPartyForm = () => (dispatch) => {
  dispatch(showActionLoading());
  requestThirdPartyForm().then(
    response => dispatch(successResponse(response, '3rdpartyform')),
    err => dispatch(errorResponse(err)),
  );
};

export const addThirdPartyType = dataToSubmit => (dispatch) => {
  dispatch(showActionLoading());
  postThirdPartyType(dataToSubmit).then(
    response => dispatch(successResponse(response, 'add3rdpartytype')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const fetchThirdPartyDetail = id => (dispatch) => {
  dispatch(showActionLoading());
  requestThirdPartyDetails(id).then(
    response => dispatch(successResponse(response, 'detail')),
    err => dispatch(errorResponse(err)),
  );
};

export const addThirdPartyReturn = (dataToSubmit, id = null) => (dispatch) => {
  dispatch(showActionLoading());
  postThirdPartyReturn(dataToSubmit, id).then(
    response => dispatch(successResponse(response, 'addthirdparty')),
    err => dispatch(errorResponse(err, false)),
  );
};
